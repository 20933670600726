// `main.js` is the file that sbt-web will use as an entry point
(function(requirejs) {
'use strict';

// -- RequireJS config --
requirejs.config({
	// Packages = top-level folders; loads a contained file named 'main.js"
	packages : [ 'common', 'home', 'users', 'login', 'signup', 'geneticists', 'categories', 'profiledata', 'laboratories','stats', 'search', 'audit', 'profiles', 'matches', 'bulkupload', 'roles', 'pedigree', 'biomaterialtype', 'scenarios', 'matchesGroups', 'inbox', 'locus', 'kits', 'trace', 'pedigreeMatches', 'pedigreeMatchesGroups','superiorinstance','inferiorinstance','profileapproval','motives', 'reporting','mutations','profileExporter', 'profileExporterToLims'],
	shim : {
		'jsRoutes' : {
			deps : [],
			// it's not a RequireJS module, so we have to tell it what var
			// is returned
			exports : 'jsRoutes'
		},
		// Hopefully this all will not be necessary but can be fetched from
		// WebJars in the future
		'angular' : {
			deps : [ 'jquery' ],
			exports : 'angular'
		},
		'angular-file-upload' : {
			deps : [ 'angular' ],
			exports : 'angular'
		},
		'angular-hotkeys' : {
			deps : ['angular'],
			exports : 'angular'
		},
		'cryptojs-aes' : {
			deps : [ 'cryptojs-core' ],
			exports : 'CryptoJS'
		},
		'cryptojs-enc' : {
			deps : [ 'cryptojs-core' ],
			exports : 'CryptoJS'
		},
		'angular-route' : [ 'angular' ],
		'angular-cookies' : [ 'angular' ],
		'bootstrap' : [ 'jquery' ],
		'ng-bootstrap' : [ 'angular', 'bootstrap' ],
		'ng-select' : [ 'angular', 'bootstrap' ],
		'i18next' : [ 'jquery' ],
		'ng-i18next' : [ 'angular', 'i18next' ],
		'qrcodejs' : {exports : 'QRCode'},
        'jquery-ui': ['jquery'],
        'angular-ui-sortable': ['jquery-ui']
    },
	paths : {
        'requirejs' :  '//cdn.jsdelivr.net/webjars/requirejs/2.1.14-1/require.min' ,
        'jquery' :  '//cdn.jsdelivr.net/webjars/jquery/3.1.1-1/jquery.min' ,
        'angular' :  '//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular.min' ,
        'angular-route' :  '//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular-route.min' ,
        'angular-cookies' :  '//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular-cookies.min' ,
        'bootstrap' :  '//cdn.jsdelivr.net/webjars/bootstrap/3.3.7-1/js/bootstrap.min' ,
        'ng-bootstrap' :  '//cdn.jsdelivr.net/webjars/angular-ui-bootstrap/0.13.3/ui-bootstrap-tpls.min' ,
        'ng-select' :  '//cdn.jsdelivr.net/webjars/angular-ui-select/0.13.1/select.min' ,
        'angular-file-upload' : '//cdn.jsdelivr.net/webjars/angular-file-upload/4.1.3/ng-file-upload.min',
        'angular-hotkeys' : '//cdn.jsdelivr.net/webjars/angular-hotkeys/1.4.0/hotkeys.min',
        'cryptojs-core' :  '//cdn.jsdelivr.net/webjars/cryptojs/3.1.2/components/core-min',
        'cryptojs-enc' :  '//cdn.jsdelivr.net/webjars/cryptojs/3.1.2/components/enc-base64-min' ,
        'cryptojs-aes' :  '//cdn.jsdelivr.net/webjars/cryptojs/3.1.2/rollups/aes' ,
        'i18next' : '//cdn.jsdelivr.net/webjars/i18next/1.7.3/i18next.min',
        'ng-i18next' : '//cdn.jsdelivr.net/webjars/ng-i18next/0.3.2/ng-i18next.min',
        'sensitiveOper' : '/sensitiveOper',
        'appConf' : '/appConf',
        'jsRoutes' :  '/jsroutes' ,
        'qrcodejs' : '//cdn.jsdelivr.net/webjars/qrcodejs/07f829d/qrcode.min',
        'd3': '//cdn.jsdelivr.net/webjars/d3js/3.5.5-1/d3.min',
        'dagre-d3': '//cdn.jsdelivr.net/webjars/dagre-d3/0.4.10/dagre-d3.min',
        'jquery-ui': '//cdn.jsdelivr.net/webjars/jquery-ui/1.11.1/jquery-ui.min',
        'angular-ui-sortable': '//cdn.jsdelivr.net/webjars/angular-ui-sortable/0.13.0/sortable.min',
        'lodash': '../lib/lodash/lodash'
    }
});


// Load the app. This is kept minimal so it doesn't need much updating.
require([ 'angular', 'angular-cookies', 'angular-route', 'jquery',
		'bootstrap', 'ng-bootstrap', 'ng-select', 'angular-hotkeys', 
		'angular-file-upload', 'ng-i18next', 'qrcodejs', 'dagre-d3',
		'jquery-ui', 'angular-ui-sortable', './app' ], function(angular) {
	
	angular.module('jm.i18next').config(['$i18nextProvider', function ($i18nextProvider) {
		$i18nextProvider.options = {
			lng: 'es-AR',
			useCookie: false,
			useLocalStorage: false,
			fallbackLng: 'en',
			getAsync: false, 
			resGetPath: '/assets/locales/__lng__/__ns__.json',
			defaultLoadingValue: '' // ng-i18next option, *NOT* directly supported by i18next
		};
	}]);

	var ngApp = angular.bootstrap(document, ['ngRoute', 'ngCookies', 'jm.i18next', 'ui.bootstrap', 'ngFileUpload', 'ui.select', 'cfp.hotkeys','app'], { strictDi: true });
	ngApp.get('$rootScope').$broadcast('pdg.loaded');

});


})(requirejs);

define("main", function(){});

